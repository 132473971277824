export const ROUTES = {
  PUBLIC_DEFAULT: '/',
  PUBLIC_SEARCH: '/search',
  PUBLIC_ABOUT: '/about',
  PUBLIC_COMPANIES: '/companies',
  PUBLIC_CONTACT: '/contact',
  PUBLIC_LOGIN: '/login',
  PUBLIC_REGISTER: '/register',
  AUTORIZED_DASHBOARD_HOME: '/autorized/dashboard_home',
  AUTORIZED_TEAM_GROUP: '/autorized/team_group',
  AUTORIZED_CUSTUMERS: '/autorized/custumers',
  AUTORIZED_CARS: '/autorized/cars',
  AUTORIZED_UPDATE_DESTRYCTIVE_CAR_INFO: '/autorized/update_destructive_car_info/:id',
  AUTORIZED_CARS_SEARCH: '/autorized/cars_search',
  AUTORIZED_SAVED_CAR_PARTS: '/autorized/cars/saved_car_parts/:id',
  AUTORIZED_GARAGE: '/autorized/garage',
  AUTORIZED_GARAGE_CAR_PARTS: '/autorized/garage/car_parts/:id',
  AUTORIZED_GARAGE_SAVED_PARTS: '/autorized/garage/saved_parts/:id',
  AUTORIZED_CAR_PARTS: '/autorized/car_parts',
  AUTORIZED_CAR_PART_EDIT: '/autorized/car_parts/edit/:id/:partId',
  AUTORIZED_CAR_PARTS_SEARCH_RESULT: '/autorized/car_parts_search',
  AUTORIZED_SETTINGS: '/autorized/settings',
  AUTORIZED_CHARTS: '/autorized/charts'
};
